@import "../../app/@theme/styles/themes";
@import "angular-tree-component/dist/angular-tree-component.css";
// @import "ag-grid-community/dist/styles/ag-grid.css";
// @import "ag-grid-community/dist/styles/ag-theme-balham.css";
// @import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";


/* @import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
@import "@ag-grid-community/all-modules/dist/styles/ag-theme-material.css"; */

// @import "../assets/framework7.bundle.scss";
// @import "../assets/phone.scss";

/*
Mini erp
GLOBAl STYLES
*/
$statusList: 'success', 'info', 'warning', 'danger', 'primary', 'basic';
$themeList: 'default', 'dark', 'cosmic', 'corporate';

@each $theme in $themeList {
  .nb-theme-#{$theme} nb-sidebar.expanded {
    width: 18rem;
  }

  .nb-theme-#{$theme} nb-sidebar .main-container {
    width: 18rem;
  }
}

@include nb-install() {
  @each $status in $statusList {
    :root {
      --color-#{$status}-default: nb-theme(color-#{$status}-default);
    }

    .bg-color-#{$status}-default {
      background-color: nb-theme(color-#{$status}-default);
    }

    .text-color-#{$status}-default {
      color: nb-theme(color-#{$status}-default);
    }

    /** angular mentions */
    .mention-menu {
      color: nb-theme(text-basic-color) !important;
      background-color: nb-theme(background-basic-color-2) !important;
    }

    // .mention-item {
    //   color: nb-theme(text-basic-color);
    // }

    // .mention-active>a {
    //   color: nb-theme(color-danger-active) !important;
    //   background-color: #337ab7;
    // }
    /** End angular mentions */
  }
}

/** Color definition */
@each $status in $statusList {
  .text-color-#{$status} {
    color: var(--#{$status}) !important;
  }
}

/** End Color definition */

/*Custom ag-grid theme*/
@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

.ag-theme-alpine,
.ag-theme-alpine-dark,
.ag-theme-alpine-cosmic {
  --ag-odd-row-background-color: #f7f9fc;
  --ag-border-color: #edf1f7;

  .ag-root-wrapper {
    border: none;
  }
}

.ag-theme-alpine-dark {
  --ag-background-color: #192038;
  --ag-foreground-color: #fff;
  --ag-border-color: #192038;
  --ag-secondary-border-color: rgba(88, 86, 82, 0.5);
  --ag-modal-overlay-background-color: rgba(24, 29, 31, 0.66);
  --ag-header-background-color: #222b45;
  --ag-tooltip-background-color: #222628;
  --ag-odd-row-background-color: #222b45;
  --ag-control-panel-background-color: #222628;
  --ag-subheader-background-color: #000;
  --ag-input-disabled-background-color: #282c2f;
  --ag-input-focus-box-shadow: 0 0 2px 0.5px rgba(255, 255, 255, 0.5), 0 0 4px 3px var(--ag-input-focus-border-color);
  --ag-card-shadow: 0 1px 20px 1px black;
  --ag-disabled-foreground-color: rgba(255, 255, 255, 0.5);
  --ag-chip-background-color: rgba(255, 255, 255, 0.07);
  --ag-input-disabled-border-color: rgba(104, 104, 110, 0.3);
  --ag-input-disabled-background-color: rgba(104, 104, 110, 0.07);
}

.ag-theme-alpine-cosmic {
  --ag-background-color: #252547;
  --ag-foreground-color: #fff;
  --ag-border-color: #13132b;
  --ag-secondary-border-color: rgba(88, 86, 82, 0.5);
  --ag-modal-overlay-background-color: rgba(24, 29, 31, 0.66);
  --ag-header-background-color: #323259;
  --ag-tooltip-background-color: #222628;
  --ag-odd-row-background-color: #323259;
  --ag-control-panel-background-color: #222628;
  --ag-subheader-background-color: #000;
  --ag-input-disabled-background-color: #282c2f;
  --ag-input-focus-box-shadow: 0 0 2px 0.5px rgba(255, 255, 255, 0.5), 0 0 4px 3px var(--ag-input-focus-border-color);
  --ag-card-shadow: 0 1px 20px 1px black;
  --ag-disabled-foreground-color: rgba(255, 255, 255, 0.5);
  --ag-chip-background-color: rgba(255, 255, 255, 0.07);
  --ag-input-disabled-border-color: rgba(104, 104, 110, 0.3);
  --ag-input-disabled-background-color: rgba(104, 104, 110, 0.07);
}

.ag-floating-filter-body {
  align-items: center;
}

.ag-cell-items-center {
  display: flex;
  align-items: center;
}

.ag-cell-justify-start {
  display: flex;
  justify-content: start;
}

.ag-cell-justify-center {
  display: flex;
  justify-content: center;
}

.ag-cell-justify-end {
  display: flex;
  justify-content: end;
}

.ag-cell-no-padding-left {
  padding-left: 0;
}

.ag-cell-no-padding-right {
  padding-right: 0;
}

.ag-cell-image {
  .ag-cell-wrapper {
    width: 100%;
    height: 100%;
  }

  .ag-cell-value {
    width: 100%;
    height: 100%;
  }
}

.ag-cell-select2-input,
.ag-cell-full-width {
  .ag-cell-wrapper {
    width: 100%;
  }
}

.ag-loading {
  border-radius: 3px;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/loading3.gif);
  overflow: hidden;
}

.ag-cell-wrapper {
  overflow: hidden;
}

.ag-cell-2line-wrap {
  display: flex;

  .ag-cell-value {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1rem;
    white-space: initial;
  }
}

// .ag-row-odd {
//   // background-color: var(--ag-odd-row-background-color);
//   background-color: transparent !important;
// }
// .ag-row-odd-override {
//   background-color: var(--ag-odd-row-background-color) !important;
//   // background-color: inherit !important;
// }

// Style for Filter reset command
// .ag-header-row-column-filter:last-child {
//   padding-right: 0;
//   .ag-floating-filter-full-body {
//     flex-direction: row-reverse;
//   }
// }

.ag-floating-filter:last-child {
  padding-right: 0;

  .ag-floating-filter-full-body {
    flex-direction: row-reverse;
  }
}

/*End Custom ag-grid theme*/

/* style inject  */

.nowrap {
  white-space: nowrap;
}

/* end style inject*/

/* Layout */
body {
  min-width: inherit !important;
  --header-nav-height: 5.05rem;
}

body nb-layout[authState="logout"] .layout nb-layout-header,
body nb-layout[authState="logout"] nb-sidebar {
  display: none;
}

body nb-layout[authState="logout"] .layout .layout-container {
  padding: 0;
}

body nb-layout[authState="logout"] nb-layout-header.fixed ~ .layout-container {
  min-height: 110vh;
  margin-top: -10vh;
}

body .logo-container {
  width: calc(18rem - 1.25rem) !important;
}

.full-width {
  width: 100%;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

// Multi theme Colors
.nb-theme-default {
  .color-darkslategray {
    color: darkslategray;
  }
}

.nb-theme-dark,
.nb-theme-cosmic {
  .color-darkslategray {
    color: #4ea7a7;
  }

  tree-node-content span {
    color: #fff;
  }

  .node-content-wrapper-focused,
  .node-content-wrapper:hover {
    tree-node-content span {
      color: #000;
    }
  }
}

.tag {
  // background: #8f9bb3;
  margin: 2px;
  padding: 2px 7px;
  // color: #fff;
  // border-radius: 0.2rem;
  line-height: 1rem;
  padding-top: 2px;
  float: left;
  margin-bottom: 2px;
  // font-size: 12px;

  // background-color: #6b6b6b40;
  // border-radius: 3px;
  // color: #ffffff;
  color: #8f9bb3;
  font-size: 13px;
  font-weight: bold !important;
}

.tag nb-icon {
  font-size: 14px;
}

.tag .label {
  padding-left: 3px;
  padding-right: 3px;
}

.tag .label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.tag .close-btn {
  cursor: pointer;
}

a.tag {
  cursor: pointer;
  font-size: 11px;

  &:hover {
    // background-color: #807f7f85 !important;
    text-decoration: underline !important;
  }
}

.nb-theme-dark,
.nb-theme-cosmic,
.nb-theme-default,
.nb-theme-corporate {
  .link {
    color: #274bdb !important;
    cursor: pointer;

    &:hover {
      color: #274bdb !important;
      text-decoration: underline !important;
    }
  }

  nb-progress-bar.status-success .progress-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

hr {
  margin-top: 0;
  margin-bottom: 1rem;
}

ngx-header ngx-action-control-list {
  float: initial !important;
  height: 2rem;
  margin-left: 0.5rem;

  .buttons-row {
    [nbInput].size-small {
      // line-height: 1.35rem !important;
      margin-bottom: initial !important;
    }

    [nbButton].size-small nb-icon {
      height: 1.25rem !important;
    }

    [nbButton].size-small {
      margin-bottom: initial !important;
    }

    .select2-container .select2-selection .select2-selection__rendered {
      line-height: 1.5rem !important;
      padding: 0.2rem 1.125rem !important;
    }

    .action-list-item.select2 {
      margin-bottom: 0rem !important;
    }
  }
}

.nb-theme-default a {
  color: #2baee0;
  text-decoration: none;
}

/* End Layout */

/** Select2 */
.select2-container--default {
  .select2-selection--multiple {
    .select2-selection__choice__remove {
      border-radius: 3px;
      border: none;
      position: initial;
    }

    .select2-selection__choice {
      margin: 2px;
    }
  }
}

.disable-select2 {

  select2,
  .select2 {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: textfield;
    /* background-color: white; */
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    /* font: 400 11px system-ui; */
    width: 100% !important;
    display: inline-block;
  }

  /* .form-group select2, .form-group .select2 {
  width: 100% !important;
} */
  .select2 {
    color: #192038 !important;
    font-family: Open Sans, sans-serif !important;
    -webkit-appearance: none;
    transition-duration: 0.15s;
    transition-property: border, background-color, color, box-shadow;
    transition-timing-function: ease-in;
    min-height: 40px;
  }

  .select2 .select2-selection {
    background-color: #f7f9fc !important;
    border-color: #edf1f7 !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: #192038 !important;
    font-family: Open Sans, sans-serif !important;
    -webkit-appearance: none !important;
    transition-duration: 0.15s !important;
    transition-property: border, background-color, color, box-shadow !important;
    transition-timing-function: ease-in !important;
    font-size: 0.9375rem !important;
    font-weight: 600 !important;
    line-height: 1.5rem !important;
  }

  .select2-container--default {

    .select2-selection--single .select2-selection__placeholder,
    .select2-selection--multiple .select2-selection__placeholder {
      font-size: 14px !important;
    }
  }

  // .select2-container--default .select2-selection--single .select2-selection__placeholder,
  // .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  //     font-size: 14px !important;
  // }
  .select2-selection.select2-selection--single,
  .select2-selection.select2-selection--multiple {
    height: 40px !important;
    color: #192038 !important;
    font-family: Open Sans, sans-serif !important;
  }

  .select2-selection.select2-selection--single:focus,
  .select2-selection.select2-selection--multiple:focus,
  .select2-container--open .select2-selection.select2-selection--single,
  .select2-container--open .select2-selection.select2-selection--multiple {
    height: 40px !important;
    outline: none;
    border-color: #274bdb !important;
    box-shadow: 0 0 0 0.375rem rgba(143, 155, 179, 0.16);
  }

  .select2-dropdown {
    outline: none;
    border-color: #274bdb !important;
    box-shadow: 0 0 0 0.375rem rgba(143, 155, 179, 0.16);
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered,
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    line-height: 37px !important;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 37px !important;
    color: #192038 !important;
    font-family: Open Sans, sans-serif !important;
  }

  .select2-selection--single .select2-selection__arrow {
    top: 7px;
    right: 10px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.5rem !important;
  }

  .select2-container--open .select2-dropdown--below {
    border-color: #edf1f7 !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: #192038 !important;
  }

  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #e4e9f2;
  }

  .select2-container--default .select2-search--dropdown .select2-search__field:focus {
    outline: none;
  }

  select2.disabled .select2-container--default .select2-selection__rendered {
    color: rgba(143, 155, 179, 0.48) !important;
  }

  select2.disabled .select2-selection.select2-selection--single:focus {
    outline: none;
    box-shadow: none !important;
    border-color: #edf1f7 !important;
    /* background-color: #f7f9fc !important; */
    /* border-color: #edf1f7 !important; */
  }

  .select2-container .select2-selection--multiple {
    min-height: 40px !important;
  }

  .nb-theme-dark select2 .select2-selection.select2-selection--single {
    background-color: #192038 !important;
    border-color: #151a30 !important;
  }

  .nb-theme-dark select2 .select2-selection.select2-selection--single .select2-selection__rendered {
    color: #ffffff !important;
  }

  .nb-theme-dark select2.disabled .select2-container--default .select2-selection__rendered {
    color: rgba(143, 155, 179, 0.48) !important;
  }

  .nb-theme-dark select2.disabled .select2-selection.select2-selection--single:focus {
    outline: none !important;
    border-color: #151a30 !important;
    box-shadow: none !important;
    /* background-color: #f7f9fc !important; */
    border-color: initial !important;
  }

  .nb-theme-dark select2 .select2-selection.select2-selection--single:focus,
  .nb-theme-dark select2 .select2-container--open .select2-selection.select2-selection--single {
    outline: none !important;
    border-color: #274bdb !important;
    box-shadow: 0 0 0 0.375rem #2e3a59 !important;
  }

  .nb-theme-dark .select2-dropdown {
    background-color: #222b45 !important;
    border-color: #274bdb !important;
    box-shadow: 0 0 0 0.375rem #2e3a59 !important;
  }
}

.select2-container {
  // width: 100%;
  // line-height: 1.5rem;
  padding: 0;

  .select2-selection__choice__display {
    white-space: normal;
  }

  .select2-selection__arrow {
    top: 0.4rem !important;
    right: 0.3rem !important;
    width: 12px !important;
  }

  .select2-selection {
    background-color: #f7f9fc !important;
    border-color: #edf1f7 !important;
    border-style: solid;
    border-width: 1px;
    border-radius: 0.25rem !important;
    color: #192038;
    font-family: Open Sans, sans-serif;
    -webkit-appearance: none;
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    -webkit-transition-property: border, background-color, color, box-shadow;
    transition-property: border, background-color, color, box-shadow;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    overflow: visible;
    font-size: 0.9375rem;
    font-weight: 600 !important;
    margin: initial !important;
    padding: initial !important;
    // border: none !important;
    // background: none !important;
    min-height: initial !important;
    height: initial !important;
    outline: 0;

    .select2-selection__choice {
      // line-height: 1.7rem !important;
      line-height: 1.66rem !important;
      padding: 0.4375rem 1.125rem;
      font-weight: 600 !important;
      background-color: #edf0f5 !important;
      border: 1px solid #ced4de !important;
    }

    li.select2-selection__choice {
      padding: 0 0.325rem;
    }

    .select2-selection__placeholder {
      line-height: 1.5rem !important;
      padding: 0;
      font-weight: normal;
      color: #99a4ba;
    }

    .select2-selection__rendered {
      line-height: 1.5rem !important;
      padding: 0.4375rem 1.125rem;
      padding-right: 2rem;
    }

    .select2-selection__rendered:empty {
      display: none;
    }

    ul.select2-selection__rendered {
      padding: 0rem 0.225rem;
    }

    .select2-selection__clear {
      position: absolute;
      right: 1.1rem;
      top: 0.44rem;
      width: 1.3rem;
      margin-right: 0;
    }

    .select2-selection--single .select2-selection__arrow {
      top: 7px;
      right: 8px;
    }

    ß .select2-selection__arrow {
      height: 2.3rem !important;
    }
  }

  .select2-selection--multiple {
    min-height: 2.5rem !important;
  }

  .select2-search--inline {
    .select2-search__field {
      margin-top: 0 !important;
      padding: 0.75rem 0;
      // min-height: 34px !important;
      min-height: 2.2rem !important;
      padding-left: 0.5rem !important;
    }
  }

  .select2-dropdown {
    overflow: hidden;
    outline: none;
    border-color: #274bdb !important;

    &.select2-dropdown--above {
      box-shadow: 0px -12px 0 0.375rem rgba(143, 155, 179, 0.16);
    }

    &.select2-dropdown--below {
      box-shadow: 0px 12px 0 0.375rem rgba(143, 155, 179, 0.16);
    }

    .select2-search__field {
      outline: 0;
    }

    // &.is_tags {
    .select2-results {
      .select2-results__options {
        .select2-results__option.new {
          color: #00d68f;

          &::after {
            content: " - new";
          }
        }

        .item-wrap {
          display: flex;
        }

        .item-thumbnail {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          border: 1px solid var(--gray);
          margin-right: 0.5rem;
        }

        .item-text {
          flex: 1;
        }
      }
    }

    // }
  }
}

.select2-container--default .select2-results > .select2-results__options {
  &::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.3125rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #e4e9f2;
    cursor: pointer;
    border-radius: 0.15625rem;
  }

  &::-webkit-scrollbar-track {
    background: #f7f9fc;
  }
}

.nb-theme-dark {
  .select2-container--default .select2-results > .select2-results__options {
    &::-webkit-scrollbar-thumb {
      background: #101426;
      cursor: pointer;
      border-radius: 0.15625rem;
    }

    &::-webkit-scrollbar-track {
      background: #192038;
    }
  }

  .select2-container .select2-selection .select2-selection__clear {
    color: #fff;
  }
}

.nb-theme-cosmic {
  .select2-container--default .select2-results > .select2-results__options {
    &::-webkit-scrollbar-thumb {
      background: #13132b;
      cursor: pointer;
      border-radius: 0.15625rem;
    }

    &::-webkit-scrollbar-track {
      background: #252547;
    }
  }
}

.select2-container--focus,
.select2-container--open {
  .select2-selection {
    outline: none !important;
    border-color: #274bdb !important;
    box-shadow: 0 0 0 0.375rem rgba(143, 155, 179, 0.16);
  }
}

.select2-container:hover {
  .select2-selection {
    outline: none !important;
    border-color: #274bdb !important;
  }
}

.select2-container--open.select2-container--below {
  .select2-selection {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom: 0 !important;
  }
}

.select2-container--open.select2-container--above {
  .select2-selection {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0 !important;
    border-top: 0 !important;
  }
}

.select2-results {
  background: #f7f9fc;

  .select2-results__options {
    .select2-results__option {
      .select2-results-option-with-image {
        display: flex;

        .image-wrap {
          background-repeat: no-repeat;
          background-size: cover;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 0.2rem;
          background-image: url(../icon/eva/image-outline.svg);

          .image {
            background-repeat: no-repeat;
            background-size: cover;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.2rem;
          }
        }

        .text {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.form-group[state="warning"] {
  .select2-container {
    .select2-selection {
      border-color: var(--warning) !important;
    }
  }
}

.form-group[state="danger"] {
  .select2-container {
    .select2-selection {
      border-color: var(--danger) !important;
    }
  }
}

.form-group[state="primary"] {
  .select2-container {
    .select2-selection {
      border-color: var(--primary) !important;
    }
  }
}

.form-group[state="success"] {
  .select2-container {
    .select2-selection {
      border-color: var(--success) !important;
    }
  }
}

.form-group[state="info"] {
  .select2-container {
    .select2-selection {
      border-color: var(--input-info-border-color) !important;
    }
  }
}

.form-details hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-color: var(--gray);
  border-style: dashed;
}

.nb-theme-dark {
  .select2-container {
    .select2-selection {
      background-color: #192038 !important;
      border-color: #151a30 !important;
      color: #ffffff !important;

      .select2-selection__rendered {
        color: #ffffff !important;
      }

      .select2-selection__choice {
        background-color: #212b44 !important;
        border: 1px solid #3b4c75 !important;
      }
    }

    .select2-dropdown {
      background-color: #192038 !important;
      border-color: #274bdb !important;
      color: #ffffff !important;

      .select2-search__field {
        border: 1px solid #111526 !important;
        background: transparent !important;
        color: #fff !important;

        &::placeholder {
          color: #818ca4 !important;
          font-weight: normal !important;
        }
      }

      &.select2-dropdown--above {
        box-shadow: 0px -12px 0 0.375rem #2e3a59;
      }

      &.select2-dropdown--below {
        box-shadow: 0px 12px 0 0.375rem #2e3a59;
      }
    }

    .select2-search__field {
      background: transparent !important;
      color: #fff;

      &::placeholder {
        color: #818ca4 !important;
        font-weight: normal !important;
      }
    }
  }

  .select2-results {
    background: #192038 !important;
  }

  .select2-results__option[aria-selected="true"] {
    background-color: #33426b !important;
  }

  .select2-container:hover {
    .select2-selection {
      outline: none !important;
      border-color: #274bdb !important;
    }
  }

  .select2-container--focus,
  .select2-container--open {
    .select2-selection {
      outline: none !important;
      border-color: #274bdb !important;
      box-shadow: 0 0 0 0.375rem rgba(143, 155, 179, 0.16);
    }
  }

  .select2-container--default {
    .select2-results__option--selected {
      background-color: #8a89897d;
    }
  }

  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    color: #fff !important;
  }

  .select2-container--disabled {

    .select2-selection .select2-selection__rendered,
    .select2-selection__choice__display,
    .select2-selection--multiple .select2-selection__clear {
      color: rgba(143, 155, 179, 0.48) !important;
    }

    &.select2-container:hover {
      .select2-selection {
        outline: none !important;
        border-color: #101426 !important;
      }
    }

    &.select2-container--focus,
    &.select2-container--open {
      .select2-selection {
        outline: none !important;
        border-color: #101426 !important;
        box-shadow: none !important;
      }
    }

  }
}

.nb-theme-cosmic {
  .select2-container {
    .select2-selection {
      background-color: #242547 !important;
      border-color: #1a1b37 !important;
      color: #ffffff !important;

      .select2-selection__rendered {
        color: #ffffff !important;
      }

      .select2-selection__choice {
        background-color: #3d4071 !important;
        border: 1px solid #536ba5 !important;
      }

      .select2-selection__placeholder {
        color: #ababd2 !important;
      }
    }

    .select2-dropdown {
      background-color: #192038 !important;
      border-color: #7b51db !important;
      color: #ffffff !important;

      .select2-search__field {
        border: 1px solid #111526 !important;
        background: transparent !important;
        color: #fff !important;
      }

      &.select2-dropdown--above {
        box-shadow: 0px -12px 0 0.375rem #6a6a94;
      }

      &.select2-dropdown--below {
        box-shadow: 0px 12px 0 0.375rem #6a6a94;
      }
    }

    .select2-search__field {
      background: transparent !important;
      color: #fff !important;
    }
  }

  .select2-results {
    background: #232547 !important;
  }

  .select2-results__option[aria-selected="true"] {
    background-color: #383d71 !important;
  }

  .select2-container:hover {
    .select2-selection {
      outline: none !important;
      border-color: #7b51db !important;
    }
  }

  .select2-container--focus,
  .select2-container--open {
    .select2-selection {
      outline: none !important;
      border-color: #7b51db !important;
      box-shadow: 0 0 0 0.375rem #6a6a94;
    }
  }
}

@include nb-install() {

  @each $status in $statusList {
    select2 {
      &.status-#{$status} {
        .select2-selection {
          // border-color: nb-theme(color-#{$status}-600) !important;
          border-color: nb-theme(color-#{$status}-default-border) !important;
        }
      }
    }
  }
}

/* End select2*/

.input-group > select2.form-control {
  padding: 0px !important;
}

/* [nbInput], [nbSelect]{
  width: 100%;
} */

body ng2-smart-table table tr td,
body ng2-smart-table table tr th {
  padding: 0.575rem 0.575rem;
}

/* Chat bar */

body nb-layout-header.fixed ~ .layout-container nb-sidebar.chat-sidebar .main-container {
  padding: 0;
  min-width: initial !important;
}

body nb-sidebar.chat-sidebar .scrollable {
  padding: 0;
  overflow-y: hidden;
}

/* body nb-layout-header.fixed ~ .layout-container nb-sidebar.chat-sidebar {
  width: 20rem;
} */

body nb-layout-header.fixed ~ .layout-container nb-sidebar.chat-sidebar .main-container {
  width: 24rem;
}

body nb-sidebar.chat-sidebar nb-chat .messages {
  padding: 0.5rem 0.5rem;
}

body nb-sidebar.chat-sidebar,
body nb-sidebar.chat-sidebar .main-container,
body nb-sidebar.chat-sidebar.expanded,
body nb-sidebar.chat-sidebar.expanded .main-container {
  width: 24rem;
}

body nb-sidebar.chat-sidebar.collapsed,
body nb-sidebar.chat-sidebar.collapsed .main-container,
body nb-sidebar.chat-sidebar.compacted,
body nb-sidebar.chat-sidebar.compacted .main-container {
  width: 0;
}

body nb-sidebar.chat-sidebar.compacted.fixed.right ~ .content {
  margin-right: 0;
  margin-left: 3.5rem;
}

/* End chat bar */

/* Layout */

/* body nb-layout .layout .layout-container .content .columns nb-layout-column {
  padding: 0.7rem 0.7rem 0.75rem !important;
} */

body nb-layout-header nav {
  height: 3.05rem !important;
  padding: 0.5rem !important;
}

body nb-layout-header.fixed ~ .layout-container {
  padding-top: 3.05rem !important;
}

body nb-layout-header.fixed ~ .layout-container nb-sidebar .main-container {
  height: calc(100vh - 3.05rem) !important;
}

body nb-layout .layout .layout-container nb-sidebar.fixed,
body nb-layout .layout .layout-container nb-sidebar .main-container-fixed {
  top: 3.05rem !important;
}

/* body nb-layout .layout .layout-container .content nb-layout-footer nav {
  padding: 0.5rem !important;
} */

.cdk-global-overlay-wrapper .cdk-overlay-pane {
  // overflow: auto !important;
  // width: 100% !important;
  height: initial;
  // max-height: initial;
}

.cdk-global-overlay-wrapper.dialog {
  pointer-events: initial;
}

.cdk-global-overlay-wrapper .cdk-overlay-pane nb-card {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  margin: 0 auto;
}

.cdk-global-overlay-wrapper nb-dialog-container {
  width: 100% !important;
}

.cdk-global-overlay-wrapper.dialog {
  padding-bottom: 1rem;
}

// .cdk-global-overlay-wrapper.dialog:nth-child(4) {
//   padding-top: 2.3rem;
// }
// .cdk-global-overlay-wrapper.dialog:nth-child(6) {
//   padding-top: 4.6rem;
// }
// .cdk-global-overlay-wrapper.dialog:nth-child(8) {
//   padding-top: 6.9rem;
// }
// .cdk-global-overlay-wrapper.dialog:nth-child(10) {
//   padding-top: 8.2rem;
// }
// .cdk-global-overlay-wrapper.dialog:nth-child(12) {
//   padding-top: 10.5rem;
// }

// .cdk-global-overlay-wrapper.dialog:nth-child(5) {
//   padding-top: 2.3rem;
// }
// .cdk-global-overlay-wrapper.dialog:nth-child(7) {
//   padding-top: 4.6rem;
// }
// .cdk-global-overlay-wrapper.dialog:nth-child(9) {
//   padding-top: 6.9rem;
// }
// .cdk-global-overlay-wrapper.dialog:nth-child(11) {
//   padding-top: 8.2rem;
// }
// .cdk-global-overlay-wrapper.dialog:nth-child(13) {
//   padding-top: 10.5rem;
// }

// .toastr-overlay-container,
// .cdk-global-overlay-wrapper {
//   overflow-x: hidden !important;
// }

body nb-progress-bar.status-danger .progress-value {
  white-space: nowrap;
}

/* End layout */

/* Smart Table */

body ng2-smart-table table tr.ng2-smart-titles th:first-child {
  width: 5%;
}

body ng2-smart-table table tr:nth-child(odd) th:first-child:hover,
body ng2-smart-table table tr:nth-child(odd) td:first-child:hover {
  background-image: linear-gradient(to right, #d9dce0a6, #edf1f700);
}

body ng2-smart-table table tr:nth-child(even) th:first-child:hover,
body ng2-smart-table table tr:nth-child(even) td:first-child:hover {
  background-image: linear-gradient(to right, #d9dce05e, #edf1f700);
}

// body ng2-smart-table table tr.ng2-smart-titles th:first-child input {
//   /* width: 20px;
//   height: 16px; */
// }
.ng2-smart-actions input.form-control {
  width: initial !important;
}

body ng2-smart-table .form-control:focus {
  box-shadow: none !important;
}

body ng2-smart-table table tr th {
  vertical-align: top !important;
}

body ng2-smart-table table tr th,
body ng2-smart-table table tr td {
  padding: 0.2rem 0.2rem !important;
}

body ng2-smart-table table tr td:first-child,
body ng2-smart-table table tr th:first-child {
  padding-left: 1rem !important;
}

body ng2-smart-table table tr td:last-child,
body ng2-smart-table table tr th:last-child {
  padding-right: 1rem !important;
}

body ng2-smart-table table tr td {
  font-size: 0.8375rem !important;
  line-height: 1.15rem !important;
}

body ng2-smart-table table tr th,
body ng2-smart-table table tr td {
  border: none !important;
  border-bottom: 1px solid #7373733d !important;
}

body ng2-smart-table th.ng2-smart-actions-title-add a {
  padding: 0.38rem 0.5rem !important;
}

body ng2-smart-table .no {
  text-align: left;
}

// .nb-theme-dark
//   ng2-smart-table
//   thead
//   tr.ng2-smart-filters
//   th
//   .ng2-smart-filter
//   input {
//   background: #182037 !important;
//   border-color: #14192f !important;
//   color: #fff !important;
// }

// .nb-theme-cosmic
//   ng2-smart-table
//   thead
//   tr.ng2-smart-filters
//   th
//   .ng2-smart-filter
//   input {
//   background: #232547 !important;
//   border-color: #191b36 !important;
//   color: #fff !important;
// }

@media (max-width: 50rem) {
  nb-card.small-header {
    margin-top: 3rem;
  }
}

nb-card.no-border {
  border: none;
}

nb-card.small-header nb-card-header {
  padding: 0.5rem 1rem !important;
  display: block !important;
}

nb-card.small-header nb-card-header nav {
  height: 2.45rem !important;
  line-height: 2.45rem !important;
}

// nb-card.small-header nb-card-header nav button,
// nb-card.small-header nb-card-header nav ngx-select2 {
//   /* float: right; */
//   // margin: 0;
//   // margin-left: 0.5rem !important;
//   // display: inline-block;
// }
// div.action-list button {
//   margin: 0;
//   margin-left: 0.5rem !important;
//   display: inline-block;
//   line-height: 1.25rem !important;
// }
// [size="tiny"],
// nb-card.small-header div.action-list button {
//   // height: 2.45rem;
//   // /* float: right; */
//   // margin-top: 1px;
// }
// nb-card.small-header nb-card-header button {
//   // line-height: 1.25rem !important;
// }
// nb-card.small-header div.action-list {
//   // float: right;
// }
// nb-card.small-header {
//   // overflow: hidden;
// }
nb-card.smart-table nb-card-body {
  padding: 0;
}

nb-card.popup nb-card-body {
  padding: 1rem 1.25rem;
}

nb-card nb-tabset nb-tab {
  padding: 0 !important;
}

// ul.action-list li button {
// }
// ul.action-list li input {
// }
.nb-theme-default ng2-smart-table thead tr.ng2-smart-filters th .ng2-smart-action i.nb-edit,
.nb-theme-default ng2-smart-table thead tr.ng2-smart-filters th .ng2-smart-action i.nb-trash {
  display: none;
}

.nb-theme-default ng2-smart-table[select="selected"] thead tr.ng2-smart-filters th .ng2-smart-action {
  background-color: #dc3545 !important;
}

.nb-theme-default ng2-smart-table[select="selected"] thead tr.ng2-smart-filters th .ng2-smart-action:hover {
  background-color: #fb6977 !important;
}

.nb-theme-default ng2-smart-table[select="selected"] thead tr.ng2-smart-filters th .ng2-smart-action i.nb-edit,
.nb-theme-default ng2-smart-table[select="selected"] thead tr.ng2-smart-filters th .ng2-smart-action i.nb-trash {
  display: block;
}

.nb-theme-default ng2-smart-table[select="selected"] thead tr.ng2-smart-filters th .ng2-smart-action i.nb-plus {
  display: none;
}

// ng2-smart-table {
// .thumbnail {
//   background-size: cover;
//   background-repeat: no-repeat;
//   width: 3rem;
//   min-height: 3rem;
//   border-radius: 0.3rem;
// }
// }
@media (max-width: 40rem) {
  .ng2-smart-table-wrap table {
    min-width: 40rem;
  }
}

.ng2-smart-filter input[nbinput],
.ng2-smart-filter select[nbinput] {
  font-size: 0.9375rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  padding: 0.4375rem 1.125rem !important;
}

/* End Smart Table */

/* Base component */
.component-state {
  padding-bottom: 1rem;
}

/* End base component */

/* Form */

.form-group > label {
  // display: none !important;
  // position: absolute;
  // z-index: 1;
  // top: -10px;
  // left: 8px;
  // padding: 1px;
  // display: flex;
  // align-items: center;

  // &span{
  //   border-radius: 0.25rem;

  // }
  margin-bottom: 0rem;
  display: flex;
  align-items: center;
  height: 22px;
}

// .form-detail-item [class*="col-"] {
//   // padding-bottom: 7px;
// }

// .form-details .form-detail-item {
//   /* padding: 3px; */
// }
// .form-details .form-detail-item:nth-of-type(even) {
//   /* background-color: #ebebeb; */
// }
nb-card-footer .buttons-row {
  margin: -0.5rem !important;
}

// .buttons-row button[nbButton] {
//   // margin: 0.5rem !important;
// }
.buttons-row button[nbButton].size-tiny {
  margin: 0.1rem !important;
}

.buttons-row button[nbButton].size-small {
  margin: 0.2rem !important;
}

.buttons-row button[nbButton].size-medium {
  margin-top: 0.3rem !important;
  margin-bottom: 0.3rem !important;
}

.buttons-row > button[nbButton].size-medium {
  margin: 0.3rem !important;
}

.buttons-row .actions-card {
  height: 8rem !important;
}

body .form-group[state="warning"] label {
  color: #ffaa00;
}

body .form-group span.invalid {
  display: none;
}

body .form-group[state="warning"] span.invalid {
  display: inline;
}

body .form-group[state="warning"] span.valid {
  display: none;
}

/* body .form-group[state="warning"] label {
  font-style: italic;
} */

.fel {
  width: 100%;
}

.form-group {
  .form-group-icon-control {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
  }

  input.form-group-input-control {
    padding-left: 40px !important;
  }

  ngx-select2.form-group-input-control {
    .select2 .select2-selection__rendered {
      padding-left: 40px !important;
    }
  }
}

body .form-group[state="warning"] label,
body .form-group[state="warning"] input,
body .form-group[state="warning"] select,
body .form-group[state="warning"] textarea,
body .form-group[state="warning"] input:focus,
body .form-group[state="warning"] select:focus,
body .form-group[state="warning"] textarea:focus {
  border-color: #ffaa00 !important;
}

.form-group {
  position: relative;
  margin-bottom: 0.5rem;
}

// .form-group > label {
//   &.show-label {
//     float: left;
//     width: initial;
//   }
// }

.form-group .float-icon-area {
  // z-index: 1;
  // right: -14px;
  // top: -14px;
  // right: 0;
  // top: -7px;
  color: #7d7d7da6;

  nb-icon {
    cursor: pointer;
  }

  &.hide-label {
    position: absolute;
    right: 0;
    top: -7px;
    z-index: 1;
  }

  &.show-label {
    float: left;
  }
}

.dialog-wrap {
  // max-width: 90%;
  margin: auto;
}

.dialog-wrap.dialog,
.dialog-wrap.popup {
  // padding-bottom: 2rem;

  max-width: 97vw;

  .smart-table {
    min-height: 80vh;
  }
}

// .cdk-global-overlay-wrapper,
// .cdk-overlay-container {
//   pointer-events: initial;
// }
// .cdk-overlay-pane:has(ngx-showcase-dialog) {
//   width: 100%;
// }
// .copy-btn {
//   // position: absolute;
//   z-index: 1;
//   right: -14px;
//   top: -14px;
// }

.fit-fist-col,
.fit-last-col {
  padding-right: 9px;
  padding-left: 9px;
}

.fit-row {
  .fit-fist-col {
    width: 60px;
  }

  .fit-last-col {
    width: 60px;
    text-align: right;

    button > nb-icon {
      height: 1.15rem !important;
    }
  }

  .fit-content-column {
    width: calc(100% - 120px);
  }

  .fit-control-button {
    width: 50px;
    margin-bottom: 3px;
  }

  .fit-sequence {
    text-align: center;
    width: 40px;
  }
}

.fit-row.form-detail-header,
.fit-row.form-detail-footer {
  .fit-control-button {
    width: 50px;
    margin-bottom: 3px;
    height: 2rem;
  }
}

.form-detail-header {
  .column {
    line-height: 2rem;
    // border-bottom: 1px solid #7978786b;
    margin-bottom: 3px;
    text-decoration: underline;
  }
}

/* End form */

/* Button */

// body [nbButton] {
//   /* margin: 0.3rem; */
// }

/* End Button */

/* Dialog */

nb-card-footer [nbButton] {
  float: right;
}

nb-card-footer [nbButton]:first-child {
  float: left;
}

@media (min-width: 768px) {

  // .cdk-overlay-container .popup {
  //   max-width: 90%;
  //   margin: auto;
  // }
  .cdk-overlay-container {
    z-index: 1050;
  }
  .cdk-overlay-container .dialog-list {
    max-width: 95%;
    margin: auto;
  }

  .cdk-global-overlay-wrapper.dialog .cdk-overlay-pane {
    width: 100%;
  }
}

.dialog-wrap.dialog,
.dialog-wrap.popup {
  // padding-bottom: 2rem;
  max-width: 90vw;

  .smart-table {
    min-height: 90vh;
  }
}

form.dialog-wrap.popup {
  max-width: 99vw;
}

@media (min-width: 1920px) {

  div.dialog-wrap.dialog,
  div.dialog-wrap.popup {
    max-width: 95vw;

    .smart-table {
      min-height: 95vh;
    }
  }
}

@media (max-width: 1920px) {

  div.dialog-wrap.dialog,
  div.dialog-wrap.popup {
    max-width: 95vw;

    .smart-table {
      height: 95vh;
    }
  }
}

@media (max-width: 1600px) {

  div.dialog-wrap.dialog,
  div.dialog-wrap.popup {
    max-width: 97vw;

    .smart-table {
      min-height: 95vh;
    }
  }
}

@media (max-width: 1400px) {
}

@media (max-width: 1368px) {

  div.dialog-wrap.dialog,
  div.dialog-wrap.popup {
    max-width: 95vw;

    .smart-table {
      min-height: 97vh;
    }
  }
}

@media (max-width: 1024px) {

  div.dialog-wrap.dialog,
  div.dialog-wrap.popup {
    max-width: 97vw;

    .smart-table {
      min-height: 97vh;
    }
  }
}

@media (max-width: 768px) {

  div.dialog-wrap.dialog,
  div.dialog-wrap.popup {
    padding-bottom: 2rem;

    max-width: 99vw;

    .smart-table {
      min-height: 99vh;
    }
  }
}

/* .cdk-overlay-pane, .cdk-overlay-pane nb-dialog-container {
  width: 100%;
} */

.cdk-global-overlay-wrapper {
  overflow: auto;
}

/* End Dialog */

/* Card */

nb-card-header nb-icon {
  line-height: 1rem !important;
}

/* End Card */

/* Tree */

tree-node-content span {
  color: #192038;
  font-family: Open Sans, sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

/* End Tree */

/* Icon */

nb-icon.fa {
  margin-top: 0.2rem;
  height: 1.05rem;
}

/* End Icon */

/* AG-Grid */

.ag-theme-balham .ag-root {
  border: none;
}

.ag-theme-balham-dark .ag-root-wrapper {
  background: transparent;
}

[class^="ag-theme-balham"] .ag-ltr .ag-cell {
  display: flex;
  align-items: center;
}

nb-card.ag-grid-card nb-card-body .ag-grid-wrap {
  /* margin: -1rem -1.25rem; */
  height: 100%;
}

// nb-card.ag-grid-card {
//   // overflow: hidden;
// }
nb-card.ag-grid-card nb-card-body {
  padding: 0;
}

.ag-grid-wrap {
  border: var(--ck-color-shadow-inner) 1px solid;
  border-radius: 5px;
  overflow: hidden;
}

ag-grid-angular {
  .image-thumb-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 5px;
  }

  .image-thumb {
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }

  // .ag-row .ag-cell {
  //   display: flex;
  //   // justify-content: center; /* align horizontal */
  //   align-items: stretch;
  // }
}

/*End AG-Grid*/

/* Layout */

.nb-theme-dark,
.nb-theme-default,
.nb-theme-corporate,
.nb-theme-cosmic {
  nb-layout .layout .layout-container .content .columns nb-layout-column {
    padding: 0.95rem 0.95rem 0.95rem;
  }
}

// body nb-layout .layout .layout-container .content .columns nb-layout-column {
//   padding: 0.95rem 0.95rem 0.95rem;
// }

.row {
  margin-right: -9px;
  margin-left: -9px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-is-1,
.col-is-2,
.col-is-3,
.col-is-4,
.col-is-5,
.col-is-6,
.col-is-7,
.col-is-8,
.col-is-9,
.col-is-10,
.col-is-11,
.col-is-12,
.col-is,
.col-is-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto,
.col-xxxl-1,
.col-xxxl-2,
.col-xxxl-3,
.col-xxxl-4,
.col-xxxl-5,
.col-xxxl-6,
.col-xxxl-7,
.col-xxxl-8,
.col-xxxl-9,
.col-xxxl-10,
.col-xxxl-11,
.col-xxxl-12,
.col-xxxl,
.col-xxxl-auto {
  padding-right: 9px;
  padding-left: 9px;
}

.nb-theme-default nb-card {
  margin-bottom: 1.075rem;
}

// body nb-layout ::-webkit-scrollbar-thumb {
//   // background: blue;
// }
// body nb-layout ::-webkit-scrollbar {
//   width: 0.5125rem !important;
// }
// body nb-layout ::-webkit-scrollbar-thumb {
//   background: #46464682 !important;
// }
body nb-sidebar nb-menu {
  padding-bottom: 5rem;
}

.cdk-global-scrollblock {
  overflow-y: hidden !important;
  top: initial !important;
}

/* End Layout */

/* Fotter */

body .socials {
  font-size: 1rem !important;
}

body nb-layout .layout .layout-container .content nb-layout-footer nav {
  font-size: 0.8rem !important;
}

body nb-layout .layout .layout-container .content nb-layout-footer nav {
  padding: 0.2rem !important;
}

body nb-layout .layout .layout-container .content nb-layout-footer nav {
  line-height: 1rem !important;
}

/* End Fotter */

/* My List style */

// .m-list {
// }
// .m-list-item,
// .m-list-filter {
//   border-bottom: 1px solid #69696921;
//   color: #192038;
//   font-family: Open Sans, sans-serif;
//   font-size: 0.9375rem;
//   font-weight: 400;
//   line-height: 1.25rem;
//   // width: 100%;
// }
// .m-list-item-property {
//   // padding: 1.5rem;
// }

/* End My List style */

/* Action list */

.action-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

// .action-list .action-list-item {
//   // margin: 0;
//   // margin-left: 0.5rem !important;
//   // display: inline-block;
//   // line-height: 1.25rem !important;
// }
// .action-list input[type="text"].action-list-item {
//   margin-top: 1px;
//   padding: 0.54rem 1.125rem;
// }
// ngx-header .action-list input[type="text"].action-list-item {
//   padding: 0.27rem 1.125rem;
// }

/* End Action list */

.framework7 {
  height: 100%;

  .f7root {
    height: 100%;

    .ios,
    .md {
      height: 100%;
    }
  }

  .navbar .title {
    width: 100%;
    text-align: center;
  }
}

/** ngx-uploader */

.is-drop-over {
  border: 1px solid #fe4676 !important;
}

nb-card.is-drop-over nb-card-header {
  background-image: -webkit-gradient(linear,
    left top,
    right top,
    from(#ff708d),
    to(#ff3d71)) !important;
  background-image: linear-gradient(to right, #ff708d, #ff3d71) !important;
  color: #ffffff !important;
}

/** End ngx-uploader */

/** Icon, Button,... */

// .icon-start:not(.icon-end) nb-icon {
//   margin-right: 0.35rem !important;
// }
.icon-end:not(.icon-start) nb-icon {
  margin-left: 0.35rem !important;
}

// body [nbButton].size-medium {
//   line-height: 1.3rem !important;
// }
body [nbButton].size-small {
  line-height: 0.9rem !important;
}

body [nbButton].size-small {
  padding: 0.5rem 0.6375rem !important;
}

body [nbButton].size-small.icon-start:not(.icon-end) nb-icon {
  margin-right: 0.25rem;
}

body [nbButton].size-small nb-icon {
  height: 1.12rem !important;
}

body [nbButton].appearance-hero.size-medium {
  padding: 0.75rem 0.78rem !important;
  min-width: 2.7rem !important;
}

[dir="ltr"] .nb-theme-dark [nbButton].size-medium.icon-start:not(.icon-end) nb-icon,
[dir="ltr"] .nb-theme-default [nbButton].size-medium.icon-start:not(.icon-end) nb-icon {
  margin-right: 0.3rem !important;
}

/** End Icon, Button */

/** NB Input */

.nb-theme-default [nbInput].size-medium {
  min-width: 2.7rem;
}

/** End NB Input */

/** Common */

/** End Common */

/** Button */

.nb-theme-default,
.nb-theme-dark,
.nb-theme-cosmic,
.nb-theme-corporate {
  [nbButton].size-medium nb-icon {
    font-size: 1.275rem;
  }
}

/** End button */
// .nb-theme-default ngx-notification-menu {
//   background-color: #ffffff;
//   border-color: transparent;
//   border-style: solid;
//   border-width: 0;
//   border-radius: 0.25rem;
//   box-shadow: 0 0.5rem 1rem 0 #2c33491a;
//   min-width: 10rem;
//   max-width: 15rem;
// }
// .nb-theme-default ngx-notification-menu nb-menu {
//   border-radius: 0.25rem;
//   overflow: hidden;
//   text-align: center;
// }
// .nb-theme-dark ngx-notification-menu {
//   background-color: #222b45;
//   border-color: transparent;
//   border-style: solid;
//   border-width: 0;
//   border-radius: 0.25rem;
//   box-shadow: 0 0.5rem 1rem 0 #1a1f33;
//   min-width: 10rem;
//   max-width: 15rem;
// }

// .notification-area {
//   nb-list-item {
//     color: var(--gray-dark);
//     cursor: pointer;
//   }
// }

// .nb-theme-dark,
// .nb-theme-cosmic {
//   .notification-area {
//     nb-list-item {
//       color: #b0b3b8;
//     }

//     nb-list-item:hover {
//       background-color: #7d7d7d21;
//     }

//     // nb-list-item.new {
//     // //   background-color: #3bb8e8d6;
//     // }
//     // nb-list-item.read {
//     // //   background-color: #82a2afd6;
//     // }
//     // nb-list-item.active {
//     // //   background-color: inherit;
//     // }
//     // nb-list-item.new:hover  {
//     // //   background-color: #3bb8e8;
//     // }
//     // nb-list-item.read:hover  {
//     // //   background-color: #82a2af;
//     // }
//     // nb-list-item.active:hover  {
//     // //   background-color: #7373733b;
//     // }
//   }
// }

// .nb-theme-default,
// .nb-theme-corporate {
//   .notification-area {
//     nb-list-item {
//       color: #6c757d;
//     }

//     nb-list-item:hover {
//       background-color: #7d7d7d21;
//     }

//     //   nb-list-item.new {
//     //     // color: #fff;
//     //     // background-color: #3bb8e8d6;
//     //   }
//     //   nb-list-item.read {
//     //     // color: #fff;
//     //     // background-color: #82a2afd6;
//     //   }
//     //   nb-list-item.active {
//     //     // color: #929292;
//     //     // background-color: inherit;
//     //   }
//     //   nb-list-item.new:hover  {
//     //     // background-color: #3bb8e8;
//     //   }
//     //   nb-list-item.read:hover  {
//     //     // background-color: #82a2af;
//     //   }
//     //   nb-list-item.active:hover  {
//     //     // background-color: #7373733b;
//     //   }
//   }
// }

.procedure-area img {
  max-width: 100%;
}

// @include nb-install-component() {
//   .select2-results {
//     .select2-results__option:not([id]) {
//       color: nb-theme(color-success-transparent-focus-border);
//       &::after {
//         content: " - new";
//       }
//     }
//   }
// }
@import "../ckeditor/theme.scss";

// .nb-theme-dark {
//   .ck-editor {
//     .ck-editor__main {
//       p {
//         color: #000;
//       }
//     }
//   }
// }

@include nb-install() {
  .dashed-radius-border {
    border: nb-theme(color-basic-transparent-focus-border) 1px dashed;
    background-color: nb-theme(background-basic-color-1);
    border-radius: 0.4rem;
    margin-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: hidden;

    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.4rem;
  }

  .solid-radius-border {
    border: nb-theme(color-basic-transparent-focus-border) 1px solid;
    background-color: nb-theme(background-basic-color-1);
    background-color: inherit;
    border-radius: 0.4rem;
    margin-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}


nb-action {
  cursor: pointer;
}

/** CKeditor */
.ck.ck-balloon-panel {
  z-index: 1040 !important;
}

/** End CKeditor */

.print-body p {
  color: black;
}


body {

  &.nb-theme-dark,
  &.nb-theme-default {

    nb-layout .scrollable-container::-webkit-scrollbar-thumb {
      background: #ff9800;
    }

    nb-layout .scrollable-container::-webkit-scrollbar {
      width: 0.43rem;
    }

  }
}

.cdk-global-overlay-wrapper.dialog {
  display: flex !important;

  &.hide-dialog {
    display: none !important;
  }
}

.cdk-overlay-backdrop {
  &.hide-dialog {
    display: none !important;
  }
}

.toastr-overlay-container {
  z-index: 1700;

  &::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.3125rem;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    cursor: pointer;
    border-radius: 0.15625rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

ngx-barcode svg {
  vertical-align: top;
}

#print-area .qr-code img {
  width: 9mm;
  height: 9mm;
}

.default-probox-one-print-footer {
  // font-style: italic;

  // position: absolute !important;
  // right: 16px;
  // bottom: 16px;
  display: none;
}

.default-print-footer {
  img {
    max-width: 100%;
  }
}


::ng-deep {
  nb-progress-bar.status-primary .progress-value {
    white-space: nowrap;
  }
}
